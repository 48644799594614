<template>
  <div id="blog-list-page">
    <!-- Blog logo -->
    <div class="blog-logo-row">
      <a href="/?blog_list">
        <img
            src="@/assets/blog.png"
            alt="blog logo"
            width="165"
            height="83">
      </a>
    </div>

    <!-- Blog grid display -->
    <div class="blog-list-body">
      <div class="blog-list-table">
        <table>
          <!-- Line 1 -->
          <tr class="blog-list-line">
            <td v-if="page === 1" class="blog-list-table-item blog-list-table-first-item" style="cursor: auto">
              Latest<br/>Articles
            </td>
            <td class="blog-list-table-item blog-list-table-item-top"
                v-for="(item, item_index) in blog_list_displayed_line1"
                :key="`blog-${item.id}${item_index}`">
              <a :href="`/?blog=${item.uid}`">
                <div class="blog-list-table-item-link">
                  <img class="blog-list-table-item-thumbnail" :src="item.data.thumbnail_image.url">
                  <div v-if="item.data?.title[0]?.text != null">
                    <span v-if="eng_app">
                      {{ item.data?.title[0]?.text }}
                    </span>
                    <span v-else class="word-wrap" v-for="(word, word_index) in item.data?.title[0]?.text.split(/\s/)"
                          :key="`list title ${item.uid} word ${word_index}`">
                      {{ word }}
                    </span>
                  </div>
                  <div class="blog-list-table-item-category">
                    {{ eng_app ? item.data.blog_category : item.data.blog_category_kr }}
                  </div>
                </div>
              </a>
            </td>
          </tr>
          <tr class="blog-list-line">
            <td v-if="page === 1" class="blog-list-table-item blog-list-table-item-bottom">
              <br>
            </td>
            <td class="blog-list-table-item blog-list-table-item-bottom"
                v-for="(item, item_index) in blog_list_displayed_line1"
                :key="`blog-${item.id}${item_index}`">
              <a :href="`/?blog=${item.uid}-link`">
                <div class="blog-list-table-item-link">
                  <div class="blog-list-table-item-bottom-link">
                    <div>{{ dateFormat(item.data.date) }}</div>
                    <img src="@/assets/svg/arrow-up-right.svg" height="22" width="22">
                  </div>
                </div>
              </a>
            </td>
          </tr>

          <!-- Line 2 -->
          <tr class="blog-list-line">
            <td class="blog-list-table-item blog-list-table-item-top"
                v-for="(item, item_index) in blog_list_displayed_line2"
                :key="`blog-${item.id}${item_index}`">
              <a :href="`/?blog=${item.uid}`">
                <div class="blog-list-table-item-link">
                  <img class="blog-list-table-item-thumbnail" :src="item.data.thumbnail_image.url">
                  <div v-if="item.data?.title[0]?.text != null">
                    <span v-if="eng_app">
                      {{ item.data?.title[0]?.text }}
                    </span>
                    <span v-else class="word-wrap" v-for="(word, word_index) in item.data?.title[0]?.text.split(/\s/)"
                          :key="`list title ${item.uid} word ${word_index}`">
                      {{ word }}
                    </span>
                  </div>
                  <div class="blog-list-table-item-category">
                    {{ eng_app ? item.data.blog_category : item.data.blog_category_kr }}
                  </div>
                </div>
              </a>
            </td>
          </tr>
          <tr class="blog-list-line">
            <td class="blog-list-table-item blog-list-table-item-bottom"
                v-for="(item, item_index) in blog_list_displayed_line2"
                :key="`blog-${item.id}${item_index}`">
              <a :href="`/?blog=${item.uid}-link`">
                <div class="blog-list-table-item-link">
                  <div class="blog-list-table-item-bottom-link">
                    <div>{{ dateFormat(item.data.date) }}</div>
                    <img src="@/assets/svg/arrow-up-right.svg" height="22" width="22">
                  </div>
                </div>
              </a>
            </td>
          </tr>
        </table>
      </div>

      <!-- Blog grid pagination -->
      <div class="blog-list-pagination">
        <div v-for="index in nb_pages"
             :key="`page button ${index}`"
             @click="setPage(index)">
          <span class="blog-list-pagination-number"
                :style="{'font-weight': index === page? '600': ''}">
            {{ index }}
          </span>
          <span v-if="index < nb_pages" class="blog-list-pagination-separator">|</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogListPage',
  data() {
    return {
      eng_app: this.$eng_app,
      eng_months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      page: 1,
      nb_pages: 1,
      blog_list: [],
      blog_list_displayed_line1: [],
      blog_list_displayed_line2: [],
    };
  },
  async mounted() {
    try {
      /// Get all the blog items
      this.blog_list = await this.$client.getAllByType(
          'blog',
          {
            lang: this.$lang,
            orderings: {field: 'my.blog.date', direction: 'desc'},
          },
      );

      this.nb_pages = Math.floor((this.blog_list.length) / 8) + 1;
      const page_nb = parseInt(new URL(window.location).searchParams.get('page'));
      if (!isNaN(page_nb) && page_nb > 0) {
        this.page = page_nb;
      }

      this.setPage(this.page);
    } catch (e) {
      window.location.replace('/?profile');
    }
  },
  methods: {
    setPage(page) {
      var i = ((page - 1) * 8) - (page === 1 ? 0 : 1);
      const limit = (page * 8) - 1;
      var new_list = [];

      while (i < this.blog_list.length && i < limit) {
        new_list.push(this.blog_list[i++]);
      }

      this.page = page;
      this.blog_list_displayed_line1 = (page === 1 ? new_list.slice(0, 3) : new_list.slice(0, 4));
      this.blog_list_displayed_line2 = (page === 1 ? new_list.slice(3, 7) : new_list.slice(4, 8));
    },
    dateFormat(str_date) {
      const date = new Date(Date.parse(str_date));
      let d = date.getDate();
      let m = this.eng_app && date.getMonth() >= 0 && date.getMonth() < this.eng_months.length
          ? this.eng_months[date.getMonth()]
          : date.getMonth() + 1;
      let y = date.getFullYear();
      return this.eng_app ? `${y} ${m} ${d}` : `${y}.${m}.${d}`;
    }
  },
}
</script>

<style scoped>
.blog-logo-row {
  display: flex;
  justify-content: center;
}

.blog-list-body {
  background-color: #ECECEE;
  padding: 4vh 4vw 4vh 4vw;
}

table {
  border-collapse: collapse;
  border-color: transparent;
}

table td, table td * {
  vertical-align: top;
}

.blog-list-table {
  padding: 2.5vmin;
  border-radius: 16px;
  background-color: white;
}

.blog-list-table-item {
  text-align: start;
  border: 1px solid #999999;
  width: 25%;
  min-height: min-content;
}

.blog-list-table-item-link {
  padding: 1.5vmin;
}

.blog-list-table-first-item {
  padding: 1.5vmin;
  font-size: 24px;
  line-height: 32px;
  font-family: 'Didot', sans-serif;
  border-bottom-color: transparent;
  letter-spacing: 1px;
}

.blog-list-table-item-thumbnail {
  height: 72px;
  width: 72px;
  border-radius: 12px;
  margin-bottom: 8px;
}

.blog-list-table-item-title {
  margin-top: 8px;
  margin-right: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: Roboto, helvetica neue, Arial, sans-serif;
  color: #222222;
}

.blog-list-table-item-category {
  margin-top: 4px;
  margin-bottom: calc(1.5vh + 4px);
  font-family: 'Whitney', sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #A47BAF;
}

.blog-list-table-item-top {
  border-bottom-color: transparent;
}

.blog-list-table-item-bottom {
  border-top-color: transparent;
}

.blog-list-table-item-bottom-link {
  display: flex;
  justify-content: space-between;
  font-family: 'Whitney', sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 700;
  color: #737373;
}

.blog-list-pagination {
  margin-top: 4vh;
  display: flex;
  justify-content: center;
}

.blog-list-pagination-number {
  padding: 8px;
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;
  color: #6b6c6c;
}

.blog-list-pagination-separator {
  margin-top: 7px;
  color: #6b6c6c;
}
</style>