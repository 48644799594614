import {createApp} from 'vue'
import App from './App.vue'
import * as prismic from '@prismicio/client'

const client = prismic.createClient('yoori-kim-design');

const kr_urls = [
    'www.yoorikim.com',
    'yoorikim.com',
];

const en_urls = [
    'www.yoorikimdesign.com',
    'yoorikimdesign.com',
];

let lang = 'en-gb';
let date_locale = 'en-US';
let eng_app = true;
if (kr_urls.includes(window.location.host)) {
    lang = 'ko-kr';
    date_locale = 'ko-KR';
    eng_app = false;
}

const app = createApp(App);
app.config.globalProperties.$client = client;
app.config.globalProperties.$lang = lang;
app.config.globalProperties.$date_locale = date_locale;
app.config.globalProperties.$eng_app = eng_app;
app.config.globalProperties.$kr_urls = kr_urls;
app.config.globalProperties.$en_urls = en_urls;
app.mount("#app");
