<template>
  <div id="article-page">
    <div class="top-navigation">
      <a class="next-prev-links color-darkpurple-hover"
         :href="prev_article_link">
        &lt; PREVIOUS
      </a>

      <div v-if="blog_item?.data?.title[0]?.text != null"
           class="title-text"
           :class="{'title-text-kr': !eng_app}">
        <span v-if="eng_app">
          {{ blog_item.data.title[0].text }}
        </span>
        <span v-else class="word-wrap" v-for="(word, word_index) in blog_item.data.title[0].text.split(/\s/)"
              :key="`title word ${word_index}`">
          {{ word }}
        </span>
      </div>

      <a class="next-prev-links color-darkpurple-hover"
         style="text-align: end"
         :href="next_article_link">
        NEXT &gt;
      </a>
    </div>

    <div v-if="blog_item?.data?.date !== null" class="date-text">
      {{ dateFormat(blog_item?.data?.date) }}
    </div>

    <div v-if="blog_item?.data?.images_list !== null">
      <div class="blog-item" v-for="(n, i) in blog_item?.data?.images_list?.length" :key="`blog item number ${n}`">
        <img class="blog-item-image"
             v-if="blog_item?.data?.images_list[i]?.image?.url != null"
             :src="blog_item?.data?.images_list[i]?.image?.url"
             :alt="`blog image ${n}`">

        <div class="blog-item-text-box" v-if="blog_item?.data?.images_list[i]?.attached_text[0] != null">
          <div v-for="(m, j) in blog_item?.data?.images_list[i]?.attached_text?.length"
               :key="`blog-attached-text-${m}`">
            <div class="blog-item-text" v-if="blog_item?.data?.images_list[i]?.attached_text[j]?.text != null">
              <span v-if="eng_app">
                {{ blog_item?.data?.images_list[i]?.attached_text[j]?.text }}
              </span>
              <span v-else
                    class="word-wrap blog-item-text-kr"
                    v-for="(word, word_index) in blog_item?.data?.images_list[i]?.attached_text[j]?.text.split(/\s/)"
                    :key="`blog-attached-text-${m}-word-${word_index}`">
                {{ word }}
              </span>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="video_id != null" class="video">
      <iframe
          frameborder="0"
          :src="`https://www.youtube.com/embed/${video_id}?mute=1&disablekb=1&rel=0&color=white`"
          title="Yoori Kim Design Intro"
          allow="autoplay; clipboard-write"
      />
    </div>


    <!-- Bottom Link Arrows -->
    <div class="bottom-arrow-links">
      <a :href="prev_article_link">
        <img class="wide-display-only" src="@/assets/svg/triangle.svg" style="transform: rotate(-90deg);">
        <img class="compact-display-only" src="@/assets/svg/chevron.svg" style="transform: rotate(180deg);">
      </a>

      <a :href="next_article_link">
        <img class="wide-display-only" src="@/assets/svg/triangle.svg" style="transform: rotate(90deg);">
        <img class="compact-display-only" src="@/assets/svg/chevron.svg">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogPage',
  props: ['categories_map'],
  data() {
    return {
      eng_app: this.$eng_app,
      blog_item: null,
      lang_link: '#',
      prev_article_link: '#',
      next_article_link: '#',
      video_id: null,
    };
  },
  mounted() {
    this.getLangUrl();
    this.getBlogData();
  },
  methods: {
    async getBlogData() {
      try {
        /// Get blog from the article uid
        const blog_uid = new URL(window.location).searchParams.get('blog');

        /// Get all the blog items
        this.blog_list = await this.$client.getAllByType(
            'blog',
            {
              lang: this.$lang,
              orderings: {field: 'my.blog.date', direction: 'desc'},
            },
        );
        if (this.blog_list.length === 0) {
          return;
        }

        const found = this.blog_list.findIndex((element) => element.uid === blog_uid);
        this.blog_item = this.blog_list[found];

        /// Get video_id if one
        const idx = (this.blog_item.data?.video_link?.embed_url ?? '').lastIndexOf('/');
        if (idx >= 0) {
          this.video_id = this.blog_item.data.video_link.embed_url.substring(idx + 1);
        }

        const prev_idx = found - 1 < 0 ? this.blog_list.length - 1 : found - 1;
        if (this.blog_list[prev_idx]?.uid != null) {
          this.prev_article_link = `/?blog=${this.blog_list[prev_idx]?.uid}`;
        }

        const next_idx = found + 1 >= this.blog_list.length ? 0 : found + 1;
        if (this.blog_list[next_idx]?.uid != null) {
          this.next_article_link = `/?blog=${this.blog_list[next_idx]?.uid}`;
        }
      } catch (e) {
        window.location.replace('/?blog_list');
      }
    },
    dateFormat(str_date) {
      const date = new Date(Date.parse(str_date));
      let d = date.getDate();
      let m = date.toLocaleString('en-US', {month: 'long'});
      let y = date.getFullYear();
      return `Posted ${m} ${d}, ${y}`;
    },
    getLangUrl() {
      let uri = '#';

      const en_index = this.$en_urls.findIndex((e) => e === window.location.host);
      const kr_index = this.$kr_urls.findIndex((e) => e === window.location.host);
      if (en_index >= 0 && en_index < this.$kr_urls.length) {
        uri = `https://${this.$kr_urls[en_index]}/${new URL(window.location).search}`;
      } else if (kr_index >= 0 && kr_index < this.$en_urls.length) {
        uri = `https://${this.$en_urls[kr_index]}/${new URL(window.location).search}`;
      }

      this.lang_link = uri;
    },
  },
}
</script>

<style scoped>
#article-page {
  margin-bottom: 24px;
}

.top-navigation {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  width: 100%;
  font-family: 'Whitney', sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 3px;
}

.next-prev-links {
  text-transform: uppercase;
  width: 116px;
}

.title-text {
  margin: 1px 2vw 0 2vw;
  font-family: Roboto, helvetica neue, Arial, sans-serif;
  font-size: 23px;
  line-height: 1.75;
  width: 100%;
  text-align: center;
}

.title-text-kr {
  font-family: 'Eulyoo', sans-serif;
  font-size: 24px;
  line-height: 1.75;
}

.date-text {
  font-family: Roboto, helvetica neue, Arial, sans-serif;
  margin-top: 24px;
  margin-bottom: 46px;
  width: 100%;
  text-align: center;
}

.blog-item {
  display: block;
}

.blog-item-text-box {
  padding: 0 8.5vw 0 8.5vw;
}

.blog-item-text {
  font-family: Roboto, helvetica neue, Arial, sans-serif;
  margin-top: 32px;
  font-size: 17px;
  line-height: 1.75;
  color: black;
  width: 100%;
}

.blog-item-text-kr {
  font-family: 'Eulyoo', sans-serif;
  font-size: 15px;
  line-height: 1.9;
}

.word-wrap {
  white-space: nowrap;
  padding-right: 4px;
}

.blog-item-image {
  margin-top: 42px;
  width: 100%;
}

.video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-top: 32px;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bottom-arrow-links {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  gap: 6px;
  width: 100%;
}

@media (max-width: 1024px) {
  .bottom-arrow-links {
    justify-content: space-around;
    gap: 0;
  }
}
</style>
