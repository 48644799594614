<template>
  <div id="category-page">
    <div v-if="category?.title[0]?.text != null">
      <span class="category-title">{{ category?.title[0]?.text }}</span>
    </div>
    <div class="category-grid">
      <a class="category-grid-card"
         v-for="item in articles_list"
         :key="`category-${item.id}`"
         :href="`/?article=${item.uid}`">
        <!-- Article thumbnail image -->
        <div class="category-grid-item-image-box">
          <img
              v-if="item?.data?.thumbnail_category_image?.url !=null"
              class="category-image"
              :src="item.data.thumbnail_category_image.url"
              :alt="item.data.title[0].text + ' logo'">
          <img
              v-else-if="item?.data?.main_image?.url !=null"
              class="category-image"
              :src="item.data.main_image.url"
              :alt="item.data.title[0].text + ' logo'">
          <div v-else></div>
        </div>

        <!-- Article Label -->
        <div v-if="item.data?.thumbnail_category_label[0]?.text != null">
          {{ item.data.thumbnail_category_label[0].text }}
        </div>
        <div v-else-if="item.data.title[0].text != null">
          {{ item.data.title[0].text }}
        </div>
        <div v-else>
          {{ '' }}
        </div>
      </a>
    </div>
    <div class="category-bottom-links">
      <a class="color-darkpurple-hover"
         href="/"
         style="letter-spacing: 3px">
        <strong>ALL PROJECTS</strong>
      </a>
      <div style="margin-top: 4px">
        <a href="/?blog_list">
          <img
              src="@/assets/blog.png"
              alt="blog logo"
              width="100"
              height="50">
        </a>
      </div>
      <div v-if="email != null">
        <strong style="font-size: 11px">CONTACT</strong>
        <span> | </span>
        <a :href="`mailto:${email}`">{{ email }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryPage',
  props: ['email'],
  data() {
    return {
      eng_app: this.$eng_app,
      category: null,
      articles_list: [],
    };
  },
  async mounted() {
    const category_uid = new URL(window.location).searchParams.get('category');
    try {
      /// Get current category
      let res_category = await this.$client.getByUID(
          'category',
          category_uid,
          {lang: this.$lang},
      );
      this.category = res_category.data;

      /// Create list of the article's uids
      let article_uids = [];
      for (let i = 0; i < res_category.data.articles_list.length; i++) {
        if ((res_category?.data?.articles_list[i]?.article_item?.uid ?? '').length > 0) {
          article_uids.push(res_category.data.articles_list[i].article_item.uid);
        }
      }

      /// Get all the articles
      this.articles_list = await this.$client.getAllByUIDs(
          'article',
          article_uids,
          {lang: this.$lang},
      );
    } catch (e) {
      window.location.replace('/?profile');
    }
  },
}
</script>

<style scoped>
#category-page {
  margin-top: 12px;
}

.category-title {
  font-family: 'Didot', sans-serif;
  letter-spacing: 1px;
  font-size: 22px;
}

.category-grid {
  margin-top: 8px;
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 12px;
  row-gap: 24px;
  width: 100%;
  font-size: 13px;
}

.category-grid-item-image-box {
  width: 100%;
  height: 0;
  object-fit: cover;
  padding-top: calc(432 / 544 * 100%);
  overflow: hidden;
  background: white;
  position: relative;
  margin-bottom: 4px;
}

.category-grid-card {
  line-height: 1.4em;
  transition: filter .2s ease-in-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.category-grid-card:hover {
  filter: opacity(0.5) grayscale(90%) drop-shadow(0 0 0 #0284c7);
}

.category-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.category-bottom-links {
  font-family: 'FFMetaPro', sans-serif;
  margin-top: 64px;
  margin-bottom: 32px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 14px;
}

@media (max-width: 1024px) {
  .category-grid {
    grid-template-columns: repeat(3, 1fr);
    font-size: 11px;
  }
}

@media (max-width: 640px) {
  .category-grid {
    grid-template-columns: repeat(2, 1fr);
    font-size: 10px;
  }
}
</style>