<template>
  <div id="main">
    <!-- Adds dynamic meta tag in head -->
    <teleport to="head">
      <meta name="description"
            :content="eng_app ? 'Yoori Kim Design is a graphic design studio. We create designs that resonate and leave a lasting impression.': '유리킴 디자인은 그래픽 디자인 스튜디오입니다. 깊은 울림과 지속적인 인상을 남기는 디자인을 추구합니다.'"/>
      <meta property="og:description"
            :content="eng_app ? 'Yoori Kim Design is a graphic design studio. We create designs that resonate and leave a lasting impression.': '유리킴 디자인은 그래픽 디자인 스튜디오입니다. 깊은 울림과 지속적인 인상을 남기는 디자인을 추구합니다.'">
    </teleport>

    <!-- Video popup -->
<!--    <Transition name="fade">-->
<!--      <div v-if="show_video_popup" class="video-popup wide-display-only">-->
<!--        <div class="video-popup-layout">-->
<!--          <div class=video-popup-navbar>-->
<!--            <div class="navbar">-->
<!--              <div class="navbar-content">-->
<!--                <img class="logo"-->
<!--                     alt="Logo"-->
<!--                     src="@/assets/logo.jpg"-->
<!--                     @click="show_video_popup = true">-->
<!--                <img class="plus-button plus-button-rotate"-->
<!--                     alt="Plus button"-->
<!--                     src="@/assets/svg/plus-icon.svg"-->
<!--                     @click="closeVideoFrame">-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="all-projects-link">-->
<!--            <a class="color-darkpurple-hover" href="/" @click="closeVideoFrame">ALL PROJECTS</a>-->
<!--          </div>-->
<!--          <VideoFrame :video_id="video_id"/>-->

<!--          <div class="video-popup-email" v-if="profile_data?.email != null">-->
<!--            <span style="font-size: 11px; font-weight: bolder">CONTACT</span>-->
<!--            <span> | </span>-->
<!--            <a :href="`mailto:${profile_data.email}`">{{ profile_data.email }}</a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </Transition>-->


    <!-- Navbar -->
    <div class="navbar" :class="{'navbar-open': show_profile}">
      <div class="navbar-content">
        <div>
          <img class="logo wide-display-only"
               alt="Logo"
               src="@/assets/logo.jpg"
               @click="show_video_popup = true">
          <a class="compact-display-only" href="/">
            <img class="logo" alt="Logo" src="@/assets/logo.jpg">
          </a>
        </div>

        <div class="tabs-bar"
             @mouseover="menu_hovered = true"
             @mouseleave="menu_hovered = false">
          <div v-show="view !== 'home'" class="tab-home wide-display-only">
            WORK
          </div>
          <div v-show="view !== 'home'"
               class="tab-city wide-display-only"
               :class="{'tab-fade': menu_hovered, 'tab-opened': city_hovered === city_index}"
               v-for="(item_list, city_index) in categories_map"
               :key="`Tab ${city_index}`"
               @mouseover="city_hovered = city_index"
               @mouseleave="city_hovered = ''">
            <div class="tab-city-text">{{ city_index }}</div>
            <div class="tab-category" v-for="category in item_list"
                 :class="{'tab-fade': city_hovered === city_index}"
                 :key="`Tab category ${category.uid}`"
                 @mouseover="city_category_hovered = category.uid"
                 @mouseleave="city_category_hovered = ''">
              <a :class="{'color-darkpurple': city_category_hovered === category.uid}"
                 :href="`/?category=${category.uid}`">
                {{ category?.title[0]?.text ?? '' }}
              </a>
            </div>
          </div>
        </div>

        <img class="plus-button"
             :class="{'plus-button-rotate' : show_profile}"
             src="@/assets/svg/plus-icon.svg"
             @click="show_profile = !show_profile">
      </div>

      <div v-if="view !== 'article'" class="lang-selector">
        <div class="lang-selector-box">
          <a :class="{'color-darkpurple-hover': !eng_app}" :href="eng_app ? null : lang_link">ENG</a>
          <div class="lang-selector-divider"></div>
          <a :class="{'color-darkpurple-hover': !eng_app}" :href="eng_app ? lang_link : null">KOR</a>
        </div>
      </div>

      <Transition name="slide">
        <div v-show="show_profile" class="profile">
          <ProfileView :profile_data="profile_data"/>
        </div>
      </Transition>
    </div>

    <!-- Body -->
    <div v-if="!show_profile">
      <HomePage
          v-if="view === 'home' && loaded"
          :categories_map="categories_map"
          :video_id="video_id"/>
      <ArticlePage
          v-else-if="view === 'article' && loaded"
          :categories_map="categories_map"/>
      <CategoryPage
          v-else-if="view === 'category' && loaded"
          :email="profile_data?.email"/>
      <BlogPage
          v-else-if="view === 'blog' && loaded"/>
      <BlogListPage
          v-else-if="view === 'blog_list' && loaded"/>
      <AboutPage
          v-else-if="view === 'about' && loaded"
          :profile_data="profile_data"/>
    </div>
  </div>

  <div v-if="view !== 'about' && !show_profile" class="compact-display-only">
    <div class="bottom-tabs-bar">
      <div class="bottom-tabs-bar-column"
           v-for="(item_list, city_index) in categories_map"
           :key="`Bottom tab ${city_index}`">
        <div class="bottom-tab-city">{{ city_index }}</div>
        <a class="bottom-tab-category"
           v-for="item in item_list"
           :key="`Bottom tab category ${item.uid}`"
           :href="`/?category=${item.uid}`">
          {{ item?.title[0]?.text ?? '' }}<br>
        </a>
      </div>
      <div class="bottom-tabs-bar-column">
        <div class="bottom-tab-city">CONTACT</div>
        <a class="bottom-tab-category" href="/?about">
          About<br>
        </a>
        <br>
        <a v-if="profile_data?.instagram_link?.url != null"
           :href="profile_data.instagram_link.url"
           target="_blank">
          <img alt="Ig logo" src="@/assets/svg/instagram.svg">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/fonts.css";
import "@/assets/styles/global.css";

import AboutPage from "@/components/AboutPage.vue";
import ArticlePage from "@/components/ArticlePage.vue";
import BlogPage from "@/components/BlogPage.vue";
import BlogListPage from "@/components/BlogListPage.vue";
import CategoryPage from "@/components/CategoryPage.vue";
import HomePage from "@/components/HomePage.vue";
import ProfileView from "@/components/ProfileView.vue";
// import VideoFrame from "@/components/VideoFrame.vue";

export default {
  name: 'App',
  components: {
    BlogPage,
    BlogListPage,
    AboutPage,
    ArticlePage,
    CategoryPage,
    HomePage,
    ProfileView,
    // VideoFrame,
  },
  data() {
    return {
      eng_app: this.$eng_app,
      view: '', /// 'home' / 'category' / 'article'
      loaded: false,
      menu_hovered: false,
      city_hovered: '',
      city_category_hovered: '',
      show_profile: false,
      profile_data: null,
      categories_map: {},
      show_video_popup: false,
      video_id: null,
      lang_link: null,
    };
  },
  mounted() {
    this.parseUrlParameters();
    this.getCategories();
    this.getProfileData();
    this.getLangUrl();

    if (localStorage.getItem('video_closed') == null
        && sessionStorage.getItem('video_closed') == null) {
      this.show_video_popup = true;
    } else {
      this.closeVideoFrame();
    }
  },
  methods: {
    parseUrlParameters() {
      const params = new URL(window.location).searchParams;
      if (params.has('article')) {
        this.view = 'article';
      } else if (params.has('category')) {
        this.view = 'category';
      } else if (params.has('blog')) {
        this.view = 'blog';
      } else if (params.has('blog_list')) {
        this.view = 'blog_list';
      } else if (params.has('about')) {
        this.view = 'about';
      } else {
        this.view = 'home';
      }

      if (params.has('profile')) {
        this.show_profile = true;
        this.show_video_popup = false;
      }
    },
    async getCategories() {
      let res = await this.$client.getAllByType(
          'category',
          {lang: this.$lang},
      );
      res = res.sort((a, b) => a.data.category_order_number - b.data.category_order_number);

      /// Stick to simple
      let map = {};
      for (let i = 0; i < res.length; i++) {
        let key = res[i].data.category_city.toUpperCase();
        let value = res[i].data;
        value['uid'] = res[i].uid;

        (key in map)
            ? map[key].push(value)
            : map[key] = [value];
      }

      this.categories_map = map;
      this.loaded = true;
    },
    async getProfileData() {
      try {
        const res = await this.$client.getSingle(
            'profile',
            {lang: this.$lang},
        );
        this.profile_data = res.data;

        /// Get the video_id
        const idx = (res.data?.intro_video?.embed_url ?? '').lastIndexOf('/');
        if (idx >= 0) {
          this.video_id = res.data.intro_video.embed_url.substring(idx + 1);
        }
      } catch (e) {
        ///
      }
    },
    getLangUrl() {
      let uri = null;

      const en_index = this.$en_urls.findIndex((e) => e === window.location.host);
      const kr_index = this.$kr_urls.findIndex((e) => e === window.location.host);
      if (en_index >= 0 && en_index < this.$kr_urls.length) {
        uri = `https://${this.$kr_urls[en_index]}/${new URL(window.location).search}`;
      } else if (kr_index >= 0 && kr_index < this.$en_urls.length) {
        uri = `https://${this.$en_urls[kr_index]}/${new URL(window.location).search}`;
      }

      this.lang_link = uri;
    },
    closeVideoFrame() {
      this.show_video_popup = false;
      localStorage.setItem('video_closed', 'yup');
      sessionStorage.setItem('video_closed', 'yup');
    }
  },
}
</script>

<style scoped>
.navbar {
  background-color: white;
  transition: all 0.8s ease-in-out;
}

.navbar-open {
  height: 100%;
}

.navbar-content {
  padding-top: 3vh;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
}

.profile {
  height: 100%;
  max-height: 100%;
  opacity: 1;
  padding-bottom: 32px;
  overflow: clip;
  background-color: white;
}

.logo {
  width: 147px;
  height: 64px;
  margin-bottom: 4vh;
  cursor: pointer;
}

.tabs-bar {
  margin-left: 1vw;
  margin-right: 2vw;
  width: 100%;
  gap: 1vw;
  display: flex;
  font-size: 12px;
  letter-spacing: 1px;
  justify-content: flex-end;
  text-align: center;
  font-family: 'Whitney', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.tab-home {
  font-size: 13px;
  min-width: 112px;
}

.tab-city {
  padding-top: 1px;
  color: black;
  cursor: pointer;
  min-width: 112px;
  opacity: 0;
  font-weight: 400;
  font-size: 13px;
  transition: opacity 0.5s ease-in-out;
}

.tab-city-text {
  margin-bottom: 4px;
}

.tab-category:hover {
  color: #7b59a3;
  font-size: 14px;
}

.tab-category {
  display: block;
  color: #6b6c6c;
  opacity: 0;
  font-weight: 400;
  font-size: 13px;
  transition: opacity 0.5s ease-in-out;
}

.tab-fade {
  opacity: 1;
}

.separator {
  height: 6px;
}

.plus-button {
  width: 38px;
  height: 38px;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
}

.plus-button-rotate {
  transform: rotateZ(225deg);
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.8s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  height: 0;
  max-height: 0;
}

.lang-selector {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  padding-bottom: 24px;
  font-size: 14px;
  letter-spacing: 3px;
}

.lang-selector-video {
  width: 63%;
  margin: 6px 9.5px 16px 0;
}

.lang-selector-box {
  display: flex;
  flex-direction: row;
}

.lang-selector-divider {
  background-color: #2a2a2a;
  width: 1.75px;
  height: 16px;
  margin: 4px 10px 0 8px;
  padding: 0;
}

.video-popup {
  z-index: 90;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: white;
}

.video-popup-navbar {
  width: 63%;
  max-width: 1234px;
  padding-top: 8px;
  padding-left: 10px;
}

.video-popup-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.video-popup-email {
  margin-top: 42px;
  font-family: 'FFMetaPro', sans-serif;
  letter-spacing: 2px;
  font-size: 14px;
}

.all-projects-link {
  font-family: 'FFMetaPro', sans-serif;
  margin-bottom: 32px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 3px;
  line-height: 3;
  font-weight: bold;
}

.bottom-tabs-bar {
  width: 100%;
  background-color: #BBA7CA;
  display: inline-flex;
  align-content: flex-start;
  justify-content: space-evenly;
  font-size: 13px;
}

.bottom-tabs-bar-column {
  padding: 20px 8px 20px 8px;
}

.bottom-tab-city {
  font-size: 11px;
}

.bottom-tab-category {
  color: white;
  font-size: 13px;
  letter-spacing: 1px;
}

.bottom-bar-end-text {
  width: 100%;
  text-align: center;
  line-height: 1.5;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.75);
  padding-top: 24px;
  padding-bottom: 24px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 1024px) {
  .logo {
    width: 120px;
    height: 53px;
  }

  .lang-selector {
    padding-bottom: 32px;
  }

  .lang-selector-box {
    flex-direction: column;
  }

  .lang-selector-divider {
    width: 38px;
    height: 1px;
    margin: 4px 0 4px 0;
    padding: 0;
  }
}

@media (max-width: 640px) {
  .logo {
    width: 108px;
    height: 48px;
  }
}
</style>
