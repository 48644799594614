<template>
  <div class="layout-grid">
    <div v-if="grid_items[0]?.image?.url != null" class="layout-grid-item">
      <img class="layout-grid-image" :src="grid_items[0].image.url" alt="Displayed image 1">
    </div>
    <div v-if="grid_items[1]?.image?.url != null" class="layout-grid-item">
      <img class="layout-grid-image" :src="grid_items[1].image.url" alt="Displayed image 2">
    </div>
    <div v-if="grid_items[2]?.image?.url != null" class="layout-grid-item">
      <img class="layout-grid-image" :src="grid_items[2].image.url" alt="Displayed image 3">
    </div>

    <!-- Small description on the right -->
    <div v-if="grid_items[3].attached_text[0].text != null" class="layout-grid-item small-description">
      <div
          class="word-wrap-box"
          v-for="(description, index) in grid_items[3].attached_text"
          :key="`description text ${index}`"
          :class="{'small-description-title': index === 0}">
        <span class="word-wrap" v-for="(word, word_index) in description.text.split(/\s/)"
              :key="`description word ${word_index}`">
          {{ word }}
        </span>
        <span v-if="index < grid_items[3].attached_text.length - 1"><br><br></span>
      </div>
    </div>
    <div v-if="grid_items[4]?.image?.url != null" class="layout-grid-item full-width">
      <img class="layout-grid-image" :src="grid_items[4].image.url" alt="Displayed image 4">
    </div>
    <div v-if="grid_items[5]?.image?.url != null" class="layout-grid-item">
      <img class="layout-grid-image" :src="grid_items[5].image.url" alt="Displayed image 2">
    </div>
    <div v-if="grid_items[6]?.image?.url != null" class="layout-grid-item">
      <img class="layout-grid-image" :src="grid_items[6].image.url" alt="Displayed image 3">
    </div>
    <div v-if="grid_items[7]?.image?.url != null" class="layout-grid-item full-width">
      <img class="layout-grid-image" :src="grid_items[7].image.url" alt="Displayed image 4">
    </div>
    <div v-if="grid_items[8]?.image?.url != null" class="layout-grid-item full-width">
      <img class="layout-grid-image" :src="grid_items[8].image.url" alt="Displayed image 4">
    </div>

    <!-- Pantone title -->
    <div v-if="grid_items[9].attached_text != null" class="layout-grid-item full-width pantone-text">
      <h2 class="pantone-title" v-if="grid_items[9]?.attached_text[0]?.text != null">
        {{ grid_items[9].attached_text[0].text }}
      </h2>
      <div class="pantone-subtitle" v-if="grid_items[9]?.attached_text[1]?.text != null">
        {{ grid_items[9].attached_text[1].text }}
      </div>
    </div>

    <!-- Bottom 4 -->
    <div v-if="grid_items[10]?.image?.url != null" class="layout-grid-item">
      <div class="bottom-4-text" v-if="grid_items[10]?.attached_text[0]?.text != null">
        {{ grid_items[10].attached_text[0].text }}
      </div>
      <img class="layout-grid-image" :src="grid_items[10].image.url" alt="Displayed image bottom 1">
    </div>
    <div v-if="grid_items[11]?.image?.url != null" class="layout-grid-item">
      <div class="bottom-4-text" v-if="grid_items[11]?.attached_text[0]?.text != null">
        {{ grid_items[11].attached_text[0].text }}
      </div>
      <img class="layout-grid-image" :src="grid_items[11].image.url" alt="Displayed image bottom 2">
    </div>
    <div v-if="grid_items[12]?.image?.url != null" class="layout-grid-item">
      <div class="bottom-4-text" v-if="grid_items[12]?.attached_text[0]?.text != null">
        {{ grid_items[12].attached_text[0].text }}
      </div>
      <img class="layout-grid-image" :src="grid_items[12].image.url" alt="Displayed image bottom 3">
    </div>
    <div v-if="grid_items[13]?.image?.url != null" class="layout-grid-item">
      <div class="bottom-4-text" v-if="grid_items[13]?.attached_text[0]?.text != null">
        {{ grid_items[13].attached_text[0].text }}
      </div>
      <img class="layout-grid-image" :src="grid_items[13].image.url" alt="Displayed image bottom 4">
    </div>

    <!-- Bottom paragraphs (p) -->
    <div v-if="grid_items[14].attached_text[0].text != null" class="layout-grid-item bottom-paragraph">
      <div v-for="(item, index) in grid_items[14].attached_text" :key="`text ${index} : ${item.text}`"
           class="bottom-paragraph-item">
        <div class="bottom-paragraph-bullet-point">•</div>
        <div>{{ item.text }}</div>
      </div>
    </div>
    <div v-if="grid_items[15].attached_text[0].text != null" class="layout-grid-item bottom-paragraph">
      <div v-for="(item, index) in grid_items[15].attached_text" :key="`text ${index} : ${item.text}`"
           class="bottom-paragraph-item">
        <div class="bottom-paragraph-bullet-point">•</div>
        <div>{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleRaemianAptLayout',
  props: ['grid_items'],
  data() {
    return {
      eng_app: this.$eng_app,
    };
  },
}
</script>

<style scoped> /* Checkout also inherited CSS from parent component ArticlePage */
.small-description {
  margin: 8px;
}

.small-description-title {
  font-weight: 700;
  font-size: 16px;
}

.pantone-text {
  display: inline-flex;
}

.pantone-title {
  padding: 0;
  margin: 20px 10px 14px 0;
  font-weight: normal;
  font-size: 20px;
}

.pantone-subtitle {
  margin-top: 18px;
  font-weight: normal;
  font-size: 8px;
}

.bottom-4-text {
  margin-top: 8px;
  margin-bottom: 4px;
  font-size: 13px;
}

.bottom-paragraph {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 1.75;
}

.bottom-paragraph-item {
  display: flex;
}

.bottom-paragraph-bullet-point {
  margin-left: 12px;
  margin-right: 8px;
}
</style>