<template>
  <div id="menu">
<!--    <div class="home-video compact-display-only">-->
<!--      <VideoFrame :video_id="video_id"/>-->
<!--    </div>-->
    <div v-bind:key="`city_${key}`" v-for="(value, key) in categories_map">
      <div class="whitney-bold home-city-text">{{ key }}</div>
      <div class="home-row">
        <a class="home-card"
           v-for="category in value"
           :key="`${key}_${category.uid}`"
           :href="`/?category=${category.uid}`">
          <div>
            <div v-if="category?.title[0]?.text != null" class="home-card-text-phone">
              {{ category.title[0].text }}
            </div>
            <div class="image-box">
              <img :src="category.category_image.url" :alt="`${category.title[0].text} logo`">
            </div>
            <div v-if="category?.title[0]?.text != null" class="home-card-text">
              {{ category.title[0].text }}
            </div>
            <div v-if="category?.category_type != null" class="whitney-bold home-card-category-text">
              {{ category.category_type }}
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import VideoFrame from "@/components/VideoFrame.vue";

export default {
  name: 'HomePage',
  // components: {VideoFrame},
  props: ['categories_map', 'video_id'],
}
</script>

<style scoped>
.home-city-text {
  margin-top: 14px;
  text-align: center;
  width: 100%;
  font-size: 16px;
  color: #2a2a2a;
}

.home-row {
  margin-top: 12px;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.home-card {
  width: 15vw;
  margin: 4px;
  padding-bottom: 12px;
  display: block;
  filter: none;
  text-align: center;
  transition: filter .2s ease-in-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.home-card:hover {
  filter: opacity(0.5) grayscale(90%) drop-shadow(0 0 0 #0284c7);
}

.home-card-text {
  font-family: 'Didot', sans-serif;
  letter-spacing: 1px;
  font-size: 20px;
  margin-top: 12px;
  color: #2a2a2a;
}

.home-card-category-text {
  font-family: 'Whitney', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 700;
  color: #737373;
}

.image-box {
  width: 100%;
  height: 0;
  object-fit: cover;
  padding-top: calc(432 / 544 * 100%);
  overflow: hidden;
  background: white;
  position: relative;
}

.image-box :hover {
  filter: none;
}

img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-card-text-phone {
  display: none;
  margin-top: 24px;
  margin-bottom: 12px;
  letter-spacing: 2px;
  font-weight: 300;
  font-size: 18px;
  color: black;
}

@media (max-width: 1024px) {
  .home-card {
    width: 35vw;
  }

  .home-video {
    margin-bottom: 42px;
  }
}

@media (max-width: 640px) {
  .home-card {
    width: 60vw;
  }

  .image-box {
    margin-bottom: 12px;
  }

  .home-video {
    margin-left: 2.5%;
  }

  .home-row {
    margin-top: 0;
    margin-bottom: 0;
  }

  .home-card-text {
    display: none;
  }

  .home-card-category-text {
    display: none;
  }

  .home-city-text {
    display: none;
  }

  .home-card-text-phone {
    display: block;
  }
}
</style>