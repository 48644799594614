<template>
  <div id="article-page">
    <div class="top-navigation">
      <a class="color-darkpurple-hover" :href="prev_article_link">&lt; PREVIOUS</a>
      <a class="color-darkpurple-hover" :href="next_article_link">NEXT &gt;</a>
    </div>
    <div v-if="article != null">
      <img class="main-image" v-if="article?.main_image?.url != null" :src="article.main_image.url">
    </div>
    <div class="text-grid">
      <!-- Col 1 -->
      <div>
        <div class="text-grid-title">
          <h1 class="word-wrap-box"
              v-if="article?.title[0]?.text != null"
              :class="eng_app ? 'text-grid-title-h1' : 'text-grid-title-h1-kr'">
            <span class="word-wrap"
                  v-for="(word, word_index) in article?.title[0]?.text.split(/\s/)"
                  :key="`word ${word_index}`">
              {{ word }}
            </span>
          </h1>
        </div>
        <div class="text-grid-description" v-if="article?.description[0]?.text != null">
          <div class="word-wrap-box"
               :class="eng_app ? 'text-grid-description-span' : 'text-grid-description-span-kr'"
               v-for="(description, index) in article.description"
               :key="`description_${index}`">
            <span class="word-wrap"
                  v-for="(word, word_index) in description.text.split(/\s/)"
                  :key="`word ${word_index}`">
              {{ word }}
            </span>
            <span v-if="index < article.description.length - 1"><br><br></span>
          </div>
        </div>
        <Transition>
          <div class="text-grid-description wide-display-only"
               v-if="article?.additional_description[0]?.text != null && read_more">
            <div class="word-wrap-box" :class="eng_app ? 'text-grid-description-span' : 'text-grid-description-span-kr'"
                 v-for="(description, index) in article.additional_description"
                 :key="`additional_description_${index}`">
              <span class="word-wrap"
                    v-for="(word, word_index) in description.text.split(/\s/)"
                    :key="`word  ${word_index}`">
                {{ word }}
              </span>
              <span v-if="index < article.additional_description.length - 1"><br><br></span>
            </div>
          </div>
        </Transition>

        <div class="text-grid-description compact-display-only" v-if="article?.additional_description[0]?.text != null">
          <div class="word-wrap-box" :class="eng_app ? 'text-grid-description-span' : 'text-grid-description-span-kr'"
               v-for="(description, index) in article.additional_description"
               :key="`additional_description_${index}`">
              <span class="word-wrap"
                    v-for="(word, word_index) in description.text.split(/\s/)"
                    :key="`word  ${word_index}`">
                {{ word }}
              </span>
            <span v-if="index < article.additional_description.length - 1"><br><br></span>
          </div>
        </div>
      </div>

      <!-- Col 2 -->
      <div class="wide-display-only">
        <div class="text-grid-year">
          <div v-if="article?.year[0]?.text != null">
            {{ article?.year[0]?.text }}
          </div>
        </div>
        <div class="text-grid-read-more">
          <div v-if="article?.additional_description[0]?.text != null"
               class="text-link-button text-grid-read-more-btn"
               :class="{'text-grid-read-more-btn-open': !read_more}"
               @click="read_more = !read_more">
            {{ read_more ? 'READ LESS' : 'READ MORE' }}
          </div>
        </div>
      </div>

      <!-- Col 3 -->
      <div class="wide-display-only">
        <div>
          <a class="text-grid-category color-darkpurple-hover" :href="category_link">
            <span class="text-grid-chevron">&gt;</span>
            {{ category_text }}
          </a>
        </div>
        <div class="text-grid-lang-btn">
          <a class="lang-btn color-darkpurple-hover" :href="lang_link">
            <span class="text-grid-chevron" style="color: white; margin-right: 4px">&gt;</span>
            {{ eng_app ? 'KOREAN' : 'ENGLISH' }}
          </a>
        </div>
      </div>
    </div>

    <!-- Bottom images layouts --->
    <ArticleRaemianAptLayout
        v-if="article?.layout === 'Raemian Apt Layout'"
        :grid_items="article?.images_list ?? []"
    />
    <ArticleDefaultLayout
        v-else
        :grid_items="article?.images_list ?? []"
        :grid_pattern="layout_grid_pattern"
    />

    <!-- Bottom Link Arrows -->
    <div class="bottom-arrow-links">
      <a :href="prev_article_link">
        <img class="wide-display-only" src="@/assets/svg/triangle.svg" style="transform: rotate(-90deg);">
        <img class="compact-display-only" src="@/assets/svg/chevron.svg" style="transform: rotate(180deg);">
      </a>
      <a :href="next_article_link">
        <img class="wide-display-only" src="@/assets/svg/triangle.svg" style="transform: rotate(90deg);">
        <img class="compact-display-only" src="@/assets/svg/chevron.svg">
      </a>
    </div>
  </div>
</template>

<script>
import ArticleDefaultLayout from "@/components/ArticleLayouts/ArticleDefaultLayout.vue";
import ArticleRaemianAptLayout from "@/components/ArticleLayouts/ArticleRaemianAptLayout.vue";

export default {
  name: 'ArticlePage',
  components: {
    ArticleRaemianAptLayout,
    ArticleDefaultLayout,
  },
  props: ['categories_map'],
  data() {
    return {
      eng_app: this.$eng_app,
      read_more: false,
      article: null,
      layout_grid_pattern: null, // for numbered or default grid
      category_text: '',
      category_link: '#',
      lang_link: '#',
      prev_article_link: '#',
      next_article_link: '#',
    };
  },
  mounted() {
    this.getArticle();
    this.getCategory();
    this.getLangUrl();
  },
  methods: {
    async getArticle() {
      const article_uid = new URL(window.location).searchParams.get('article');
      try {
        /// Get current article with url's UID
        const res_article = await this.$client.getByUID(
            'article',
            article_uid,
            {lang: this.$lang},
        );
        this.article = res_article.data;

        /// Get numbered layout (ex: "1-2-2-1-2 Layout")
        const layout = res_article?.data?.layout ?? '';
        const first_part = layout.substring(0, layout.indexOf(' '));
        const matches = first_part.match(/^[12](?:-[12])+$/) ?? [''];
        const formatted = matches[0].split('-') ?? [];

        /// res of example : [true, false, false, false, false, true, false, false];
        let res = [];
        formatted.forEach((e) => {
          if (e === '2') {
            res.push(false);
            res.push(false);
          } else {
            res.push(true);
          }
        });
        this.layout_grid_pattern = res;
      } catch (e) {
        window.location.replace('/?profile');
      }
    },
    async getCategory() {
      try {
        /// Get category from the article uid
        const article_uid = new URL(window.location).searchParams.get('article');
        const categories_list = Object.values(this.categories_map).flat();
        for (let i = 0; i < categories_list.length; i++) {
          const articles_list = categories_list[i]?.articles_list ?? [];
          const found = articles_list.findIndex((element) => {
            return element.article_item.uid === article_uid;
          });

          /// Get what's needed
          if (found >= 0) {
            /// --> Category text & link
            this.category_text = categories_list[i]?.title[0]?.text ?? '';
            this.category_link = `/?category=${categories_list[i].uid}`;

            /// --> Prev link
            let prev_article_idx = found - 1;
            let category_idx = i;

            // If first article of category,
            // Link to prev category, last article
            if (prev_article_idx < 0) {
              category_idx--;
              if (category_idx < 0) {
                category_idx = categories_list.length - 1;
              }
              prev_article_idx = categories_list[category_idx].articles_list.length - 1;
            }
            const prev_article_uid = categories_list[category_idx].articles_list[prev_article_idx].article_item.uid;
            this.prev_article_link = prev_article_uid != null ? `/?article=${prev_article_uid}` : '#';

            /// --> Next link
            let next_article_idx = found + 1;
            category_idx = i;

            // If last article of category,
            // Link to next category, first article
            if (next_article_idx >= articles_list.length) {
              category_idx++;
              if (category_idx >= categories_list.length) {
                category_idx = 0;
              }
              next_article_idx = 0;
            }
            const next_article_uid = categories_list[category_idx].articles_list[next_article_idx].article_item.uid;
            this.next_article_link = next_article_uid != null ? `/?article=${next_article_uid}` : '#';

            /// End
            return;
          }
        }
      } catch (e) {
        window.location.replace('/?profile');
      }
    },
    getLangUrl() {
      let uri = '#';

      const en_index = this.$en_urls.findIndex((e) => e === window.location.host);
      const kr_index = this.$kr_urls.findIndex((e) => e === window.location.host);
      if (en_index >= 0 && en_index < this.$kr_urls.length) {
        uri = `https://${this.$kr_urls[en_index]}/${new URL(window.location).search}`;
      } else if (kr_index >= 0 && kr_index < this.$en_urls.length) {
        uri = `https://${this.$en_urls[kr_index]}/${new URL(window.location).search}`;
      }

      this.lang_link = uri;
    },
  },
}
</script>

<style> /* Also applies to children */
.layout-grid {
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  width: 100%;
}

.layout-grid-item {
  width: 100%;
  object-fit: cover;
  position: relative;
}

.layout-grid-label {
  margin-bottom: 42px;
}

.full-width {
  grid-column-start: 1;
  grid-column-end: 3;
}

.layout-grid-image {
  width: 100%;
}

@media (max-width: 640px) {
  .layout-grid-item {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
</style>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease-in;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

#article-page {
  margin-bottom: 24px;
}

.top-navigation {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: 'Whitney', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 3px;
}

.main-image {
  width: 100%;
  object-fit: cover;
  position: relative;
}

.text-grid {
  margin-top: 24px;
  margin-bottom: 32px;
  display: flex;
  line-height: 1.1;
  column-gap: 42px;
  justify-content: space-between;
  width: 100%;
}

.text-grid-title {
  display: flex;
  align-content: flex-end;
  white-space: pre;
}

.text-grid-title-h1 {
  font-family: 'IowanOldStyleRegular', sans-serif;
  letter-spacing: 1px;
  line-height: 1.5;
  width: 100%;
  font-weight: normal;
  font-size: 26px;
  padding: 0;
  margin: 0;
}

.text-grid-title-h1-kr {
  font-family: 'Eulyoo', sans-serif;
  font-size: 24px;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

.text-grid-year {
  font-family: 'Eulyoo', sans-serif;
  font-size: 13px;
  width: 84px;
  padding-top: 16px;
}

.text-grid-category {
  font-family: 'Perpetua', sans-serif;
  font-size: 21px;
  width: 112px;
  display: flex;
  align-content: flex-end;
  margin-top: 13px;
  margin-bottom: 25px;
}

.text-grid-category:hover {
  font-size: 22px;
  margin-bottom: 24px;
}

.text-grid-description {
  padding-top: 24px;
  width: 100%;
  gap: 20px;
}

.text-grid-description-span {
  font-size: 17px;
  line-height: 1.75;
}

.text-grid-description-span-kr {
  font-family: 'Eulyoo', sans-serif;
  line-height: 1.9;
}

.text-grid-read-more {
  font-family: 'Perpetua', sans-serif;
  display: flex;
  align-content: flex-end;
  height: 100%;
}

.text-grid-read-more-btn {
  font-size: 13px;
  display: flex;
  align-self: flex-end;
  margin-bottom: 32px;
  transition: margin-bottom 0s linear 0.5s;
  margin-top: 1px;
}

.text-grid-read-more-btn:hover {
  font-size: 14px;
  margin-top: 0;
}

.lang-btn {
  font-size: 13px;
  letter-spacing: 1px;
  cursor: pointer;
  font-family: 'Perpetua', sans-serif;
}

.lang-btn:hover {
  font-size: 14px;
}

.text-grid-chevron {
  font-family: 'Whitney', sans-serif;
  margin-right: 8px;
  font-size: 16px;
}

.bottom-arrow-links {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  gap: 6px;
  width: 100%;
}

@media (max-width: 1024px) {
  .bottom-arrow-links {
    justify-content: space-around;
    gap: 0;
  }

  .text-grid-description-span {
    font-size: 15px;
  }

  .text-grid-description-span-kr {
    font-size: 15px;
  }

  .text-grid-title-h1 {
    font-size: 18px;
  }

  .text-grid-title-h1-kr {
    font-size: 17px;
  }
}
</style>
