<template>
  <div>
    <div class="contents-box">
      <div v-if="profile_data?.profile_image?.url != null">
        <img class="image-content" :src="profile_data.profile_image.url"
             :alt="profile_data.profile_image?.alt ?? 'Profile image'">
      </div>
      <div v-if="profile_data?.profile_text != null"
           class="text-content color-black">
        <div class="word-wrap-box"
             v-for="(description, index) in profile_data.profile_text"
             :key="`profile desc word ${index}`"
             :class="eng_app ? 'text-content-en' : 'text-content-kr'">
          <span class="word-wrap"
                v-for="(word, word_index) in description.text.split(/\s/)"
                :key="`profile desc word ${word_index}`">
            {{ word }}
          </span>
          <span v-if="index < profile_data.profile_text.length - 1"><br></span>
        </div>

        <div class="lang-btn wide-display-only">
          <a class="color-darkpurple-hover" :href="lang_link">
            {{ eng_app ? 'KOREAN' : 'ENGLISH' }}
          </a>
        </div>
      </div>
    </div>

    <!-- Original bottom bar -->
    <div class="bottom-items color-darkgrey">
      <div class="bottom-contact-title color-black">CONTACT</div>
      <div v-if="profile_data?.email != null" class="bottom-contact-email">
        <a :href="`mailto:${profile_data.email}`" class="color-darkgrey">{{ profile_data.email }}</a>
      </div>
      <div v-if="profile_data?.phone != null">
        {{ profile_data.phone }}
      </div>
      <br>
      <div v-for="(item, index) in (profile_data?.address_kr ?? [])" :key="'address_kr' + index"
           class="bottom-kr-address color-black">
        {{ item?.text }}
      </div>
      <div v-for="(item, index) in (profile_data?.address_en ?? [])" :key="'address_en' + index">
        {{ item?.text }}
      </div>
    </div>

    <!-- Mobile bottom bar -->
    <div class="compact-bottom-items">
      <div>Contact</div>
      <a v-if="profile_data?.email != null"
         :href="`mailto:${profile_data.email}`"
         class="underline">
        {{ profile_data.email }}
      </a>
      <div v-if="profile_data?.phone != null">
        {{ profile_data.phone }}
      </div>
      <br>
      <div>Website</div>
      <a class="underline" href="/">{{ current_host }}</a>
      <a v-if="profile_data?.instagram_link?.url != null"
         :href="profile_data.instagram_link.url"
         target="_blank"
         class="ig-box">
        <img alt="Ig logo" src="@/assets/svg/instagram-black.svg">
        <strong style="font-weight: normal">@yoorikimdesign</strong>
      </a>
    </div>

    <div class="bottom-bar-end-text wide-display-only">
      © 2024, Yoori Kim Design. Built by <strong>Himnerom</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileView',
  props: ['profile_data'],
  data() {
    return {
      eng_app: this.$eng_app,
      current_host: '',
      lang_link: '#',
    };
  },
  mounted() {
    this.getLangUrl();
  },
  methods: {
    getLangUrl() {
      let uri = '#';

      /// Add "?profile" or "&profile" if not there
      let params_url = new URL(window.location).search;
      if ((params_url ?? '').length === 0) {
        params_url = '?profile';
      } else if (!params_url.includes("&profile")) {
        params_url += '&profile';
      }

      const en_index = this.$en_urls.findIndex((e) => e === window.location.host);
      const kr_index = this.$kr_urls.findIndex((e) => e === window.location.host);
      if (en_index >= 0 && en_index < this.$kr_urls.length) {
        uri = `https://${this.$kr_urls[en_index]}/${params_url}`;
      } else if (kr_index >= 0 && kr_index < this.$en_urls.length) {
        uri = `https://${this.$en_urls[kr_index]}/${params_url}`;
      }

      this.lang_link = uri;
      this.current_host = new URL(window.location).host;
    },
  }
}
</script>

<style scoped>
.contents-box {
  background: #fff;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 6vw;
}

.image-content {
  width: 320px;
}

.text-content {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  text-align: start;
  color: black;
}

.text-content-en {
  line-height: 1.7;
  font-size: 16px;
  max-width: 520px;
  margin-bottom: 13px;
}

.text-content-kr {
  max-width: 460px;
  font-family: 'Eulyoo', sans-serif;
  font-weight: 500;
  margin-bottom: 13px;
  font-size: 16.5px;
  line-height: 1.8;
  color: black;
}

.lang-btn {
  font-size: 13px;
  letter-spacing: 1px;
  font-family: 'Perpetua', sans-serif;
  margin-bottom: 1.3px;
  margin-top: 12px;
  width: 0;
}

.lang-btn:hover {
  font-size: 14px;
  margin-bottom: 0;
}

.bottom-items {
  gap: 4px;
  width: 100%;
  text-align: center;
  font-size: 15px;
  margin-top: 42px;
  font-family: 'Whitney', sans-serif;
  letter-spacing: 1px;
}

.bottom-contact-title {
  font-weight: bolder;
  font-size: 14px;
}

.bottom-contact-email {
  margin-top: 2px;
  font-size: 18px;
  margin-bottom: 2px;
}

.bottom-kr-address {
  font-family: 'Eulyoo', sans-serif;
}

.compact-bottom-items {
  display: none;
}

.bottom-bar-end-text {
  width: 100%;
  text-align: center;
  padding-top: 56px;
  padding-bottom: 24px;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 300;
}

@media (max-width: 1024px) {
  .contents-box {
    flex-direction: column;
  }

  .text-content-en {
    max-width: 100%;
  }

  .text-content-kr {
    max-width: 100%;
  }
}

@media (max-width: 640px) {
  .bottom-items {
    display: none;
  }

  .image-content {
    width: 50vw;
  }

  .compact-bottom-items {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    margin-bottom: 42px;
    gap: 12px;
  }

  .ig-box {
    width: min-content;
    gap: 8px;
    display: inline-flex;
  }
}
</style>