<template>
  <div id="about">
    <ProfileView :profile_data="profile_data"/>
  </div>
</template>

<script>
import ProfileView from "@/components/ProfileView.vue";

export default {
  name: 'AboutPage',
  components: {ProfileView},
  props: ['profile_data'],
}
</script>

<style scoped>

</style>