<template>
  <div class="layout-grid">
    <div v-for="(item, index) in (grid_items ?? [])"
         :key="`Grid item ${index}`"
         class="layout-grid-item"
         :class="{'full-width': index >= grid_pattern.length || grid_pattern[index]}">
      <img v-if="item?.image?.url != null"
           class="layout-grid-image"
           :src="item.image.url"
           :alt="`Displayed image${index}`">
      <div v-if="item?.attached_text[0]?.text != null" class="layout-grid-label">
        {{ item.attached_text[0].text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleDefaultLayout',
  props: [
    'grid_items',
    'grid_pattern',
  ],
}
</script>

<style scoped> /* Checkout also inherited css from parent component ArticlePage */

</style>